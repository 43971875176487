<template>
  <div class="signature">
    <page-nav title="我的电子签章" />
    <div class="info">请上传您的公章或合同章扫描件</div>
    <div class="upload">
      <van-uploader v-model="uploadList" :after-read="afterRead" :max-count="1" :max-size="5 * 1024 * 1024" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import User from '@/common/user';
import axios from 'axios';
import { HOST } from '@/common/const';
import Fetch from '../../common/fetch';
import { GetUserInfo, UploadUserSign, UserUpdateOfficialSeal } from '../../common/interface';
import { Toast, Uploader } from 'vant';
import PageNav from '@/components/PageNav.vue';
Vue.use(Toast);
Vue.use(Uploader);

export default {
  name: 'Signature',
  components: { PageNav },
  data() {
    return {
      userInfo: null,
      uploadList: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const user = User.getInfo() || {};
      const { uuid } = user;
      const params = {
        uuid,
      };
      Fetch.post(GetUserInfo, params).then(res => {
        this.userInfo = res;
        const { officialSeal } = res;
        this.uploadList = [{ url: officialSeal, uid: officialSeal, name: 'signature' }];
        User.modifyInfo(res);
      }).catch(err => {
        Toast(err.msg || '请求失败');
      })
    },

    afterRead(file) {
      const header = {
        sourceFlag: 'web',
        channelCode: '10001',
        appOwnership: 'EBei',
      };

      const user = User.getInfo() || {};
      const { token, uuid } = user;
      if (token) {
        header.token = token;
      }
      if (uuid) {
        header.uuid = uuid;
      }

      const imageUploadAction = `${HOST}${UploadUserSign}`;

      const filedata = new FormData();
      filedata.append('file', file.file);

      axios
      .post(imageUploadAction, filedata, {
        headers: {
          ...header,
          "Content-Type": "multipart/form-data"
        },
      })
      .then(res => {
        if (res.data.code == 1000) { // 上传成功
          const data = res.data.data;
          this.uploadList[0].url = data.fileUrl;
          this.uploadList[0].uid = data.fileKey;
          this.onFinishUploadSignature(data.fileUrl);
        } else {
          Toast('上传失败请重试');
        }
      });
    },

    onFinishUploadSignature(signUrl) {
      const params = {
        officialSeal: signUrl,
      };
      Fetch.post(UserUpdateOfficialSeal, params).then(() => {
        Toast.success('保存成功');
        this.fetchData();
      }).catch((err) => {
        Toast(err.msg || '请求失败')
      });
    },

  },
}
</script>

<style lang="scss" scoped>
.signature {
  background: #fff;
  padding-bottom: 30px;
  .info {
    margin: 40px 0 20px;
    text-align: center;
    color: #666;
    font-size: 15px;
  }
  .upload {
    text-align: center;
  }
}

</style>